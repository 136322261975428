<template>
    <div>
        <!--AxPUPurchaseOrderBList.vue
        {
            pageFlag: true,
            id: "ax_PU_PurchaseOrder_b",
            title: "",
            name: "ax_PU_PurchaseOrder_b",
            icon: "el-icon-document",
            component: "views/zb/AxPUPurchaseOrderBList.vue",
            componentdetail: "views/zb/AxPUPurchaseOrderBDetail.vue",
            visible: true
        }
        -->
        <el-container>
            <el-main>
                <el-button-group>
                    <el-button type="" size="mini" @click="syslistquery">查询</el-button>
                    <el-button type="" size="mini" @click="syslistadd">新增</el-button>
                </el-button-group>
                <vxe-form size="mini">
                    <vxe-form-item title="名称" field="name" :item-render="{}" style="width:60px;">
                        <vxe-input v-model="p.name" size="mini" placeholder="名称"></vxe-input>
                    </vxe-form-item>
                </vxe-form>
                <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow highlight-hover-row size="mini" :data="rs" :height="meta.h">
                    <vxe-table-column field="guid" title="guid" :visible ="false"  width="180"></vxe-table-column>
                    <vxe-table-column field="pguid" title="pguid" width="180"></vxe-table-column>
                    <vxe-table-column field="inventorycode" title="存货编号" width="250"></vxe-table-column>
                    <vxe-table-column field="projectcode" title="项目信息" width="250"></vxe-table-column>
                    <vxe-table-column field="Quantity" title="数量" width="100"></vxe-table-column>
                    <vxe-table-column field="unitcode" title="单位" width="100"></vxe-table-column>
                    <vxe-table-column field="price" title="price" width="100"></vxe-table-column>
                    <vxe-table-column fixed="right" width="120" title="操作">
                        <template #default="{row, rowIndex }">
                            <a @click="syslistdeleteone(row)">删除 | </a>
                            <a @click="syslistqueryone(row)">详细</a>
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <vxe-pager :align="'left'" background :current-page.sync="p1.page.current" :page-size.sync="p1.page.size" :total="p1.page.total" @page-change="pagechange" :page-sizes=[100,500,1000,10000] :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                </vxe-pager>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    export default {
        name: "ax_PU_PurchaseOrder_blist",
        extends: ax.control1(),
        data() {
            return {
                p: { name: "" },
                model: "zb",
                settings: "axPUPurchaseOrderB",
                vouchertype: "list",
            }
        },
    }
</script>